/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./open-sans/open-sans-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./open-sans/open-sans-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./open-sans/open-sans-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./open-sans/open-sans-v20-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./open-sans/open-sans-v20-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./open-sans/open-sans-v20-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./open-sans/open-sans-v20-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./open-sans/open-sans-v20-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./open-sans/open-sans-v20-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./open-sans/open-sans-v20-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./open-sans/open-sans-v20-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('./open-sans/open-sans-v20-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./open-sans/open-sans-v20-latin-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }