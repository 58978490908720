.boxCard {
  border: solid 1px #DDDDDD;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.dashed {
  border-style: dashed;
}

.hoverable:hover {
  border-color: lighten(#418fd8, 25%);
}

.active,
.active:hover {
  border-color: #418fd8;
}

.hoverable:hover,
.active,
.medium {
  border-width: 2px;
  padding: 15px;
}
