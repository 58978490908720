.wizardView {
  padding-top: 40px;

  h2 {
    margin-top: 35px;
  }
}

.motionContainer {
  margin: 0 1rem;
}

.connectorBoxCard {
  margin-bottom: 35px;
  max-width: 744px;
}
