@use 'sass:math';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    list-style-type: none;
    background: #f0f2f5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
    font-size: 1.4em;
}

@mixin tlrb {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@mixin beforeAfter {
    @include tlrb;
    position: absolute;
    content: ' ';
}

// linear interpolation (color)
// returns a value between $a and $b, depending on $t
// $t=0 returns $a
// $t=1 returns $b
@function lerp($a, $b, $t) {
    @return mix($b, $a, $t * 100);
}

.scroll-on-hover-y {
    overflow-y: hidden;
    overflow-x: hidden;
}

.scroll-on-hover-y:hover {
    overflow-y: auto;
}

.cell {
    @include tlrb;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.cell-overflow {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cellDiv {
    display: block;
    /* padding-right: 2em; */
    width: 0;
    min-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: text-bottom;

    position: relative;

    &.fade::after {
        @include beforeAfter;
        left: unset;
        width: 30px;

        background: linear-gradient(to right, transparent, white);
    }
}


/*
    Fixes for react-json-view
*/
.react-json-view {
    padding-left: 1em;
    font-size: 22px;

    .icon-container {
        position: absolute;
        width: auto;
        transform: translateX(-80%) translateY(-1px);

        .expanded-icon {
            display: inline-block;
            transform: translateX(-1px);
        }

        .collapsed-icon {
            display: inline-block;
            transform: translateX(1px);
        }
    }

    span>.icon-container~span {
        display: inline !important;
    }

    .node-ellipsis {
        line-height: 0px !important;
    }

    .object-meta-data {
        user-select: none;
    }

    .variable-value,
    .string-value {
        cursor: pointer !important;
    }
}

// allow copy tooltip to attach to this
.ant-tabs-tabpane {
    position: relative;
}

.hoverButton {
    border-color: transparent;
    box-shadow: none;
}

.hoverButton:hover .ant-avatar {
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.showOnHover {
    opacity: 0.2;
}

.showOnHover:hover {
    opacity: 1;
}

.spinnerText {
    user-select: none;
    margin-left: 10px;
}

.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;

    border: 2px solid transparent;
    border-bottom-color: hsl(205, 100%, 50%);
    border-top-color: hsl(205, 100%, 50%);
    animation:
        rotateIn 1.2s cubic-bezier(0.25, 0.80, 0.9, 0.9) 0s 1,
        rotate 0.7s linear 1.2s infinite,
        spinnerFadeIn 0.5s ease 0s 1;


    @keyframes rotateIn {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(1800deg);
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes spinnerFadeIn {
        from {
            opacity: 0;
            scale: 120%;
        }

        to {
            opacity: 1;
            scale: 100%;
        }
    }
}


.pulsating {
    animation: pulse 0.5s linear 0s infinite;
}

@keyframes pulse {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.5;
    }

    to {
        transform: 1.0;
    }
}

.fadeIn {
    animation: fadeIn 0.4s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.menu-divider-group-title {
    color: transparent;
    height: 1px;
    background: #fff2;
    /* margin    : 1em; */
    text-overflow: clip;
    /* font-size : 0; */
    transition: all 0.3s ease-out 0s;
}

.menu-divider {
    color: transparent;
    height: 1px;
    background: #fff2;
    text-overflow: clip;
    transition: all 0.3s ease-out 0s;
    margin: 1em 1em;
}

.cursor-pointer {
    cursor: pointer;
}

.hoverLink:hover {
    cursor: pointer;
    color: hsl(205, 100%, 50%);
}

.pureDisplayRow {
    color: inherit;
}

/* */
/* Table size={'middle} */
/* */
.ant-table-middle {
    /* Header height */
    --headerVertical: 10px;
    /* Row height */
    --padRowHeight: 8px;
    /* Cell horizontal */
    --padCells: 12px;
    /* Outside left/right */
    --padOutside: 16px;
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
    /* Header: middle (normal + sorter)*/
    padding: var(--headerVertical) var(--padCells);
}

.ant-table.ant-table-middle .ant-table-thead>tr>th:first-child:not(.ant-table-row-expand-icon-cell) {
    /* Header: first (normal + sorter)*/
    padding-left: var(--padOutside);
}

.ant-table.ant-table-middle .ant-table-thead>tr>th:last-child {
    /* Header: last (normal + sorter)*/
    padding-right: var(--padOutside);
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
    padding: 0;
}

.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
    padding: var(--headerVertical) var(--padCells);
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td {
    /* Cell: middle */
    padding: var(--padRowHeight) var(--padCells);
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td:first-child:not(.ant-table-row-expand-icon-cell) {
    /* Cell: first */
    padding-left: var(--padOutside);
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td:last-child {
    /* Cell: last */
    padding-right: var(--padOutside);
}

.ant-table-tbody>tr:hover>td {
    transition-duration: 0s, 0s !important;
}

.ant-table-tbody>tr>td {
    transition-duration: 0s, 0s !important;
}

/* never break table headers */
.ant-table-thead>tr>th {
    white-space: nowrap;
}

/* */
/* */
/* Don't clip animated page headers */
.ant-page-header-heading {
    overflow: visible;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: unset;
}

td.ant-table-column-sort {
    background-color: unset;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
    background-color: unset;
}

.ant-pagination-total-text {
    font-size: 13px;
    opacity: 0.8;
    transform: translateY(-1px);
}

.ant-pagination-item {
    border-color: transparent;
    background-color: transparent;
}

.ant-table-pagination.ant-pagination {
    margin-bottom: 4px;
}

/* .ant-pagination-item a {
    color: hsl(205, 100%, 55%)
}
*/
.ant-pagination-item-active a {
    font-weight: bold;
    cursor: default;
}

.fadeIn3d {
    opacity: 1;
    transform: translateY(0) perspective(10em) rotateX(0) scale(1, 1);
    transition: all 0.15s ease-out;
}

.fadeOut3d {
    opacity: 0;
    transform: translateY(.1em) perspective(6em) rotateX(30deg) scale(0.85, 0.85);
    transition: all 0.1s ease-out;
}

.query-form {
    margin-bottom: 1em;
}

.error-list {
    display: flex;
    flex-direction: column;
    gap: 0.7em;

    padding: 2em;
    margin: 1em 0;
    background: hsla(205, 15%, 90%, 1);
    border-radius: 6px;
}

.codeBox {
    display: inline-block;
    margin: 0;
    padding: 0em 0.4em 0em;
    white-space: pre-wrap;
    font-size: 85%;
    font-family: monospace;

    background: rgb(241, 241, 241);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 3px;
}

.w100 {
    width: 100%;
}



.sideBarToggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    height: 40px;
    background: rgba(0, 0, 0, 0.25);
    cursor: pointer;

    .icon {
        font-size: 19px;
        color: hsl(217deg, 20%, 45%);
        transition: color 100ms;
    }

    &:hover .icon {
        color: hsl(217deg, 38%, 70%);
    }
}



.versionTitle {}

.hoverBorder {
    border-color: transparent;
}

.hoverBorder:hover {
    border-color: unset;
}

.ant-result {
    padding: 1em 2em;
}

.ant-table {
    white-space: nowrap;
}

.whiteSpaceDefault {
    white-space: normal;
}

.nowrap {
    white-space: nowrap;
}

.consumerGroupCompleteID {
    white-space: normal;
    word-break: break-all;
}

.consumerGroupName {
    white-space: nowrap;
}

.consumerGroupSuffix {
    opacity: 0.4;
    white-space: nowrap;
    font-size: 66%;
}

.groupLagDisplayLine {
    font-size: 85%;
    margin-bottom: .2em;
}

.groupLagDisplayLine:not(:last-child) {
    padding-bottom: 0.2em;
    border-bottom: 1px solid #eee;
}

.login {
    display: flex;
    place-content: center;
    place-items: center;
    height: 100vh;
    background: rgb(233, 233, 233);
    font-family: 'Quicksand', sans-serif;
}

.loginContainer {
    width: 970px;
    height: 600px;
    background: #fafafa;
    overflow: overlay;
    box-shadow: 8px 8px 16px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    display: flex;
    font-size: 22px;
    line-height: 23px;
    /* or 107% */
    display: flex;
    align-items: center;
    color: #498FC2;
}

.loginContainerRight {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: #fafafa;
    text-align: center;
    font-weight: 500;
    padding: 2rem 0;
}

.loginLeft {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 4rem;
    width: 294px;
}

.loginRight {
    position: relative;
    height: 100%;
    background: linear-gradient(338.72deg, #0a64a7 0%, #08273e 94.27%) no-repeat;
    opacity: 0.8;
    flex-grow: 1;
    /* background      : url('assets/pattern.png') repeat-x;
    background-position: bottom; */
    /* background      : linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('assets/pattern.png') repeat; */
    overflow-y: hidden;
}

.loginRight::after {
    content: "";
    background: url('assets/pattern3.png') repeat-x 330px bottom;
    background-position-y: 115%;
    mix-blend-mode: hard-light;
    opacity: 0.35;
    zoom: 80%;
    @include tlrb;
    z-index: -1;
}

.loginLogo {
    color: #f5f5f5;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    place-items: center;
}

.loginButtonList {
    display: flex;
    place-content: center;
    place-items: center;
    margin-top: 1rem;
}

.loginSeperator {
    margin: 0rem 2rem;
    width: 1px;
    min-height: 100px;
    background: rgba(255, 255, 255, 0.287);
}

.loginButton2 {
    cursor: pointer;
    width: 130px;
    height: 85px;
    font-size: 1.5em;
    font-weight: bold;
    color: rgb(23, 23, 23);
    background: #3a618899;
    border-radius: 2px;
    display: flex;
    place-content: center;
    place-items: center;
    flex-direction: column;
}

.loginButton2 i {
    font-size: 28px;
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.loginButton2:hover {
    color: #fff;
    background: hsla(5, 100%, 64%, 1);
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.loginButton2:hover i {
    font-size: 45px;
    color: #fff;
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}


ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline,
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed {

    width: auto;

    display: flex;
    flex-direction: column;
    gap: 6px;

    font-family: 'Poppins', sans-serif;

    li.ant-menu-item {
        display: flex;

        padding: 0 10px !important; // no clue why antd sets padding-left:24px; as inline style...
        border-radius: 5px;
        margin: 0 8px;
        width: auto;
        overflow: visible; // allow hit-test element of the nested link to expand beyond the list item
        font-size: 13px;


        transition:
            width 2000ms,
            color 0ms ease-out,
            background-color 100ms ease;

        a {
            display: flex; // fix vertical alignment of menu items in sidebar
            color: hsl(218deg, 16%, 66%);

            gap: 10px;
            transition:
                gap 500ms,
                color 50ms ease-out;

            .anticon+span {
                transition: none;
            }

            .menuIcon {
                $size: 22px;

                display: inline-flex;
                width: $size;
                margin: 0;

                transition:
                    margin 200ms ease-out,
                    margin-left 200ms ease-out;

                // Safari
                // workaround because safari calculates width as 0 for some reason, 'auto' doesn't work either
                svg {
                    width: $size;
                }
            }

            &::before {
                @include tlrb;
                left: -20px;
                right: -20px;
                // background-color: red;
                content: '';
            }

            &>span {
                color: unset; // remove antd default
            }

            &:hover {
                color: hsl(218deg, 16%, 90%);
            }
        }

        &-selected {
            background-color: hsl(220deg, 35%, 13%);

            a,
            .menuIcon {
                color: hsl(216deg, 13%, 90%);
            }
        }

        &-disabled {
            cursor: default;
            user-select: none;
            opacity: .5;

            a {
                color: hsl(205deg, 50%, 70%) !important;
            }
        }

    }

    // we don't need a clearfix in our menu
    &::before {
        display: none;
    }
}

ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline-collapsed {
    li.ant-menu-item {
        // place-content: center;

        a {
            gap: 0px;

            .menuIcon {
                margin-left: 10px;
            }

            &::before {
                display: none;
            }
        }
    }
}




.menu-permission-tooltip {
    user-select: none;
    box-shadow: 0 0 5px 1px #00000047;
    min-width: 203px;

    border-radius: 6px;

    .ant-tooltip-content,
    .ant-tooltip-inner {
        border-radius: 6px !important;
    }

    .ant-tooltip-arrow {
        display: none;
    }
}

.ant-checkbox-wrapper {
    user-select: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: hsla(209, 75%, 66%, 1);
    border-color: hsla(209, 50%, 55%, 1);
}

.ant-btn-primary,
.ant-switch-checked {
    background-color: hsla(209, 66%, 55%, 1);
    border-color: transparent;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: hsla(206, 80%, 55%, 1);
    background: #00b9ff17;
}

.ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs-tab {
    place-content: center;
}

.ant-tabs-ink-bar {
    background-color: hsla(209, 75%, 60%, 1);
}

button.ant-btn-circle>i.anticon:only-child {
    transform: translateY(-2px)
}

tr.tombstone {
    /* background: rgba(200, 200, 200, 0.09); */
    color: rgba(0, 0, 0, 0.25);
}

.kowlCard {
    position: relative;
    margin: 0;
    margin-bottom: .75rem;
    padding: 1rem 1.5rem;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.05);
}

.ant-tabs-nav {
    width: 100%;
}

.ant-tabs-nav>div {
    display: flex;
}

.ant-tabs-nav .ant-tabs-tab {
    flex: 1;
    text-align: center;
}

.expandedMessage {
    .ant-tabs-nav {
        .ant-tabs-tab {
            flex: none;
            padding: 0.6em 3.5em;
        }

        .ant-tabs-tab-active {
            background: none;
        }
    }
}

.overflowYOverlay {
    overflow-y: auto; // set auto by default
    overflow-y: overlay; // if possible, use 'overlay' (even though its deprecated)
}

/* tr td:nth-child(1) {
    padding-left: 30px !important;
}

tr td:nth-last-child(1) {
    padding-right: 30px !important;
} */
.ant-statistic {
    margin-right: 2rem;
}

.noSelect {
    user-select: none;
}

.iconButton {
    transition-duration: 0ms;
    transition-delay: 0ms;
}

.disableAnimation {
    animation-duration: 0s !important;
}

tr button.ant-btn-link {
    opacity: 0;
}

tr:hover button.ant-btn-link {
    opacity: 1;
}

tr button.ant-dropdown-open {
    opacity: 1;
}

// todo: fix table layout (ellipsis, never greater than min-content, only expand one row to let the table fill its parent, expand in predefined ratios, ...)
tr td.ellipsis {
    position: relative;
}

.ellipsis:before {
    content: '&nbsp;';
    visibility: hidden;
}

.ellipsis span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td.msgTableActionColumn {
    padding-left: 0;
}

.ant-table.ant-table-middle .ant-table-tbody>tr>td.msgTableActionColumn {
    padding: 0;
}

tr>td.msgTableActionColumn {
    text-align: right;
}

/* Change color of the "filter" button (which changes what columns are displayed)  */
th.msgTableActionColumn .anticon svg {
    min-width: 15px;
}

th.msgTableActionColumn .ant-table-filter-trigger-container {
    /* not sure at all if any of those two lines are a good idea */
    /* background: rgba(0, 0, 0, 0.04); */
    border-left: 1px solid rgba(184, 184, 184, 0.4);
}

.fill {
    width: 100%;
    height: 100%;
}

.roleTitle {
    margin-bottom: 1em;
    /* padding: 0 8px; */
    cursor: default;
    font-size: .7rem;
    font-weight: 600;
    /* todo: use open sans for all "small text" like labels and small headings */
    font-family: "Open Sans";
    color: rgb(139, 139, 139);
    /* border-bottom: solid 1px rgba(0, 0, 0, 0.1); */
}

.ant-table-thead>tr>th.ant-table-cell.ant-table-row-expand-icon-cell {
    padding: 0px;
}

.ant-table-tbody>tr>td.ant-table-cell.ant-table-row-expand-icon-cell {
    padding: 0px;
}

.ant-table-expand-icon-col {
    width: 1px;
}

button.ant-table-row-expand-icon {
    margin-left: 12px;
    transform: scale(1); // fixes buggy display in firefox
}

/* Fix: make it possible to configure column width */
td .ant-table-cell {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

tr.consumerGroupNoMemberAssigned {
    color: rgba(0, 0, 0, 0.3);
}

.labelText {
    margin-bottom: 3px;
    margin-left: 1px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.7em;
    font-weight: 600;
    text-transform: uppercase;
    /* background: linear-gradient(90deg, rgba(0, 0, 0, 20%) 5%, rgba(0, 0, 0, 0) 100%) bottom no-repeat;
    background-size: 100% 1px; */
    user-select: none;
}

.ant-space-horizontal {
    flex-wrap: wrap;
}

.ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane {
    transition-duration: 0.15s;
    transition-timing-function: ease-out
}

@media only screen and (max-width: 1200px) {
    .ant-statistic-title {
        font-size: 12px;
    }

    .ant-statistic-content {
        font-size: 16px;
        line-height: 1.3;
    }
}

.ant-modal {
    max-height: 80%;
}

.ant-table-column-has-sorters .ant-table-column-sorter {
    color: hsla(0, 0%, 50%, 0.3);
}

.ant-table-column-has-sorters:hover .ant-table-column-sorter {
    color: hsla(0, 0%, 50%, 0.85);
}

.messagesSpecialIconButton {
    padding: 0px 15px;
}

.ant-input-group-compact .messagesSpecialIconButton:not(:first-child) {
    border-left: 1px solid hsla(205, 100%, 90%, 0.5);
}

svg {
    display: inline-block;
    height: 100%;
    margin: auto;
}

.ant-popover {
    .ant-popover-content {
        /* popover is too hard to see on white background */
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.07);

        .ant-popover-inner {
            .ant-popover-title {
                color: hsl(0deg, 0%, 35%);
            }

            .ant-popover-inner-content {
                color: hsl(0deg, 0%, 45%);
            }
        }
    }
}

.ant-progress-status-active .ant-progress-bg::before {
    /* faster, quadratic ease-out */
    animation: ant-progress-active 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}

.ant-progress-success-bg,
.ant-progress-bg {
    // Don't animate progress (looks strange when it goes smoothly from 100% to 0%)
    transition-duration: 0s;
}

.filterTag {
    padding-left: 0;
    cursor: pointer;

    span {
        vertical-align: middle;
        height: 100%
    }
}

.ant-tag.filterTag>.anticon+span {
    margin-left: 0;
}

.filterTag .settingIconFilter,
.filterTag .filterName {
    border-right: 1px solid rgba(184, 184, 184, 0.4);
    padding: 0 6px;
    transition: 0.5 ease;
}

.filterTag .settingIconFilter:hover {
    border-right: 1px solid rgba(184, 184, 184, 0.8);
    background: rgba(206, 206, 206, 0.2);
}

@keyframes ant-progress-active {

    /* improve visibility of animation */
    0% {
        width: 0%;
        opacity: 0;
    }

    10% {
        opacity: 0.6;
    }

    100% {
        width: 100%;
        opacity: 0.2;
    }
}

.ant-message-notice-content {
    /* vertical padding is way too large */
    padding: 4px 16px;
    padding-top: 5px;
    /* shadow is too strong */
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.12), 0 3px 8px 2px rgba(0, 0, 0, 0.04);
    /* */
    border-radius: 3px;
}

.ant-tooltip {
    font-size: 12px;
    max-width: unset;
    white-space: normal;

    --ant-tooltip-color: hsl(0deg, 0%, 87%);
}


.ant-menu-inline-collapsed-tooltip a {
    color: var(--ant-tooltip-color);
}

.ant-tooltip {
    .ant-tooltip-inner {
        min-height: 20px;
        padding: .5em .75em;
        color: var(--ant-tooltip-color);
        border-radius: 4px;
        background-color: hsla(210, 14%, 20%, 0.9);
        text-align: center;
        font-size: 11px;
        font-family: Verdana, "Segoe UI", Helvetica, sans-serif;
        line-height: 1.5;
    }
}

.ant-tooltip-arrow::before {
    background-color: hsla(210, 14%, 20%, 0.9);
    width: 8px;
    height: 8px;
}

.quickTable {
    text-align: initial;
}

/* Helper classes */
.displayNone {
    display: none;
}

.marginTop1em {
    margin-top: 1em;
}

.marginTop05em {
    margin-top: .5em;
}

.h3 {
    font-weight: 500;
    padding-right: .5em;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.17em;
}

.inlineFlex {
    display: inline-flex;
}

.marginRight05em {
    margin-right: .5em;
}

.marginRight1em {
    margin-right: 1em;
}

.code-editor-textarea {
    outline: none;
}

.verticalCenter {
    vertical-align: middle;
}

.verticalCenter:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.svgCenter {
    display: inline-block;
    vertical-align: middle;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -3px;
    margin-right: -3px;
}

.messageSearchFilterBar .ant-tag {
    padding: 0px 10px;
    border-radius: 50px;
}

.resourceLabel {
    font-weight: 600;
    font-size: 100%;
    color: hsl(0, 0%, 30%);
    font-family: "Open Sans";
    cursor: default;
}

.resourceName {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 90%;
    padding-bottom: 2px;
    display: inline-block;
    padding: 0px 3px;
}

.resourceLabelSub {
    color: #606060;
}

.permissionsList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

/* Permission "Actions" */
.permissionsList span {
    margin-right: 3px;
    margin-top: 2px;
    padding: 1px 8px;
    font-size: 85%;
    text-transform: capitalize;
    border-radius: 20px;
    background: rgba(124, 211, 255, 0.35);
    color: hsl(208, 32%, 55%);
    border-width: 0;
}

.joinerOr {
    font-size: 80%;
    font-weight: bolder;
    padding-bottom: 4px;
    margin: 0 3px;
    padding: 0 1px;
    font-family: sans-serif;
    text-transform: lowercase;
    background: transparent;
}

.permissionRegex span.codeBox {
    padding: 2px 4px;
    font-size: 12px;
    color: hsl(0, 0%, 40%);
    background: rgb(219, 219, 219);
    border-width: 0px;
}

// .ant-collapse-borderless {
//     background-color: #f7fafc;
// }

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 6px;
    padding-left: 40px;
}

.roleBindingId {
    font-size: 70%;
    font-weight: 600;
    color: #aaa;
    display: flex;
}

.expander {
    background: none;

    .ant-collapse-item {
        border: 0;
    }
}

// arrows are not centered by default (maybe it has something to do with the font size of the title, or other elements in there?)
div.ant-collapse.ant-collapse-borderless>div>div>span {
    padding: 9px 0 0 !important;
}

.ant-collapse.ant-collapse-ghost {

    display: flex;
    flex-direction: column;
    gap: 6px;


    .ant-collapse-header {
        padding-left: 20px;

        .ant-collapse-arrow {
            left: 2px;
        }
    }

    .ant-collapse-content-box {
        padding: 6px 9px !important;
        padding-bottom: 2em !important;
    }

    .ant-collapse-item {
        &>.ant-collapse-header:hover {
            background: hsl(203deg, 20%, 97%);
            border-radius: 5px;
            transition: background-color 0ms;
        }

        &>.ant-collapse-header .issuesTag {
            margin-top: 2px;
            padding: 0px 6px;
            border-radius: 4px;

            border: 1px solid red;
            background: #f20;
            color: #fff;
            font-size: 0.75em;

            user-select: none;

            // default hidden
            opacity: 0;
            transform: translateX(6px);
            transition:
                transform 150ms ease-out,
                opacity 150ms ease-out;
        }

        &.hasErrors>.ant-collapse-header .issuesTag {
            opacity: 1;
            transform: translateX(0);
        }
    }


}


.subjectListExpander {
    font-size: 85%;
    padding: 0;
}

.subjectListExpander.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0 0 0 24px;
    border-radius: 3px;
    /* border: 1px transparent solid; */
    color: #777;
}

.subjectListExpander.ant-collapse>.ant-collapse-item>.ant-collapse-header:hover {
    /* border-color: hsla(205, 100%, 50%, 0.15); */
    transition-duration: 0ms;
    background-color: hsla(0, 0%, 0%, 0.08);
}

.subjectListExpander.ant-collapse>.ant-collapse-item>.ant-collapse-header span[role=img] {
    left: 8px;
    font-size: 10px;
}

.tableRowHighlightSpecial {
    background-color: rgba(255, 187, 0, 0.185);
}

/* .tableRowHighlightSpecial td:first-child::before {
    content: ' ';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -30px;
    width: 30px;
    background: red;
} */
.popoverSmall {
    .ant-popover-inner-content {
        /* reduced from 12 16 */
        padding: 8px 16px;
        font-size: 85%;
    }

    .ant-popover-title {
        min-height: 10px;
        font-size: 90%;
    }

    .ant-popover-inner {
        border-radius: 4px;
    }
}

.pageTitle {
    /* background: rgba(255, 0, 0, 0.089); */
}

.ant-breadcrumb {
    /* don't allow wrapping */
    white-space: nowrap;

    a {
        display: inline-block;
        padding: 4px 5px;
        transition-duration: 0ms;

        :hover {
            background-color: #97d4ff3f;
            border-radius: 5px;
        }
    }
}

.ant-page-header.has-breadcrumb {
    padding-top: 18px;
}

.ant-breadcrumb-separator {
    margin: 0px;
    display: inline-block;
    vertical-align: middle;
}

.userPreferencesButton {
    opacity: 0.8;
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    color: #888;
    background-color: hsla(216, 20%, 92%, 1);
}

.userPreferencesButton:hover {
    opacity: 1;
    background-color: #fff;
}

.userPreferencesButton .octicon {
    transform: rotateZ(45deg);
}

/* ant design menuitem hover is barely visible */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: hsla(205, 100%, 70%, 1);
}

.ant-menu-item,
.ant-select-item,
.ant-select-item-option {
    transition-duration: 0ms;
}

.ant-select-dropdown {
    /* transition-duration: 0ms; */
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-duration: 100ms;
}

.ant-modal.preferencesDialog {
    width: 75% !important;
    max-width: 700px;
    height: auto;
    max-height: 85%;
    display: inline-flex;
    justify-content: center;
    padding: 0;
}

.preferencesDialog {
    *[display=flex] {
        max-height: 100%;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .ant-modal-body {
        flex-grow: 1;
    }

    .ant-menu-vertical .ant-menu-item {
        height: 32px;
        line-height: 32px;
    }

    .ant-menu-vertical .ant-menu-item:not(:last-child) {
        margin-bottom: 2px;
    }

    input.ant-input,
    .ant-input-number-input {
        padding: 3px 8px;
    }

    .ant-input-number {
        width: auto;
        height: 30px;
    }

}

.smallText {
    font-family: "Open Sans", sans-serif;
    font-size: 10.5px;
    padding-left: 2px;
    color: hsla(0, 0%, 40%, 1);
    cursor: default;
}

.hideStatsBarButton {
    opacity: 0;
    transition: opacity 150ms;
    color: #888;
    display: flex;
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 32px;
    transform: translate(-27px, -20px);

    &:hover {
        background: #b0e2ff7a;
        color: hsla(205, 50%, 50%, 1);
    }

    svg {
        vertical-align: baseline;
    }
}

.statisticsBar {
    &:hover {
        .hideStatsBarButton {
            opacity: 1;
        }
    }

    .ant-row {
        row-gap: 1em;
        flex-wrap: nowrap;
        white-space: nowrap;

        overflow-x: hidden;

        &::after {
            @include beforeAfter;
            left: unset;
            width: 80px;

            background: linear-gradient(to right, transparent, white);
        }
    }
}

.filterTagDisabled {
    .filterName {
        text-decoration: line-through rgba(46, 46, 46, 0.66);
    }
}

.messageHeaders {
    border-bottom: 1px solid #ddd;

    thead th.ant-table-cell {
        background: hsla(0deg, 0%, 0%, 0.04);
        padding-top: .5em !important;
        padding-bottom: .5em !important;
    }
}

.noPadding .ant-table-cell {
    padding: 0 !important;

    div.error {
        background: #ffc8c8;
        padding: 8px 16px;
    }

    div.brokerConfigView {
        padding: 16px 16px 8px 16px;

        // .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content
        .ant-descriptions-row {

            .ant-descriptions-item-label,
            .ant-descriptions-item-content {
                padding: 6px 16px;
                border-bottom: 1px solid hsl(228, 20%, 92%);
            }

            .ant-descriptions-item-label {
                background: hsl(220, 19%, 95%);
                border-right: 1px solid hsl(228, 20%, 90%);
            }

            .ant-descriptions-item-content {
                white-space: pre-wrap;
                word-break: break-all;
                background: hsl(220, 19%, 97%);
            }
        }

        .brokerConfigViewSettings {
            .ant-radio-button-wrapper {
                height: 28px;
                line-height: 26px;
                padding: 0px 14px;
            }
        }
    }

    .codeBox {
        background: hsl(0, 45%, 80%, );
    }
}

.topicDeleteModal {
    .ant-alert {
        margin-bottom: 1rem;
    }

    .ant-modal-footer {
        border-top: none;
    }
}

.topicDocumentation {
    padding: 0 16px;

    p {
        margin-left: 2px;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0.8em;
    }

    pre {
        padding: 12px;
        font-family: monospace;
        font-size: 14px;
        line-height: 1.45;
        color: #333;
        background-color: #f5f8fb;
        border-radius: 3px;
        overflow: auto;
    }

    table {
        margin-bottom: 16px;

        td,
        th {
            padding: 6px 13px;
            border: 1px solid hsl(210deg, 7%, 86%);
        }

        th {
            font-weight: 600;
        }

        th,
        tr:nth-child(2n) {
            background-color: #f5f8fb;
        }
    }
}

.breadcrumbLast {
    display: inline-flex;
    margin-left: .5rem;
    height: 1px;
    font-size: 125%;
    font-weight: 700;
    vertical-align: middle;
    padding-bottom: 6px;
    align-items: center;

    cursor: default;
}

.radioOptionGroup {
    $borderRadius: 6px;

    width: 100%;
    display: flex;
    flex-direction: column;

    border: 1px solid hsl(0deg, 0%, 94%);
    border-radius: $borderRadius;

    user-select: none;

    &>.ant-radio-wrapper {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        padding-bottom: 16px;
        margin: 0;
        display: inline-block;

        // & span:nth-child(1) {
        //     display: inline-block;
        // }

        transition: background-color, border-color, box-shadow;
        transition-duration: 200ms;
        transition-timing-function: ease-out;

        &>span:nth-child(2) {
            display: contents;
        }

        &::after {
            display: none !important;
        }
    }

    &>.ant-radio-wrapper:first-child {
        border-radius: $borderRadius $borderRadius 0px 0px;
    }

    &>.ant-radio-wrapper:last-child {
        border-radius: 0px 0px $borderRadius $borderRadius;
    }

    &>.ant-radio-wrapper:not(:last-child) {
        border-bottom: 1px solid hsl(0deg, 0%, 94%);
    }

    &>.ant-radio-wrapper:hover:not(.ant-radio-wrapper-checked) {
        background: hsl(0deg, 0%, 99%);
    }

    &>.ant-radio-wrapper-checked {
        background: hsl(198deg, 100%, 98%);
        box-shadow: 0px 0px 2px 1px hsla(209deg, 100%, 55%, 0.66);
        z-index: 1;
    }

}



.waitingForMessagesText {
    animation: waitingForMessagesTextPulse 1.5s ease-in-out 0s infinite;
}

@keyframes waitingForMessagesTextPulse {
    0% {
        color: inherit;
    }

    40% {
        color: rgb(79, 173, 245);
    }

    60% {
        color: rgb(79, 173, 245);
    }

    100% {
        color: inherit;
    }
}

.ant-message-notice-content {
    position: relative;
}

.waitingForMessagesBox::before {
    @include beforeAfter;

    border-radius: 4px;
    box-shadow: 0 0 8px 4px #3098e8;

    animation: waitingForMessagesBoxPulseShadow 1.5s ease-in-out 0s infinite;
}

@keyframes waitingForMessagesBoxPulseShadow {
    0% {
        box-shadow: 0 0 4px 1px hsla(206deg, 10%, 85%, 50%);
    }

    50% {
        box-shadow: 0 0 8px 2px #3098e8b0;
    }

    100% {
        box-shadow: 0 0 4px 1px hsla(206deg, 10%, 85%, 50%);
    }
}

.reassignPartitions {
    .ant-steps-item-custom {
        user-select: none;

        .ant-steps-item-icon>.ant-steps-icon {
            font-size: 20px;
        }
    }

    .ant-table-expanded-row th {
        cursor: default;
    }

    .ant-table-expanded-row>td {
        &::before {
            content: ' ';
            display: block;
            position: absolute;
            box-shadow: 0px 0px 3px 0px #000000;
            top: 1px;
            left: 2px;
            right: 2px;
            bottom: 0px;
            z-index: -1;
            border-radius: 2px;
        }

        border-radius: 1px;
    }

    // section
    .currentReassignments {
        display: flex;
        place-items: center;
        margin-bottom: .5em;
        gap: .5em;

        .title {
            font-size: 1.17em;
            font-weight: 500;
            color: hsla(0deg, 0%, 0%, 85%);
            margin-left: .2em;
            padding-bottom: 2px;
        }

        button {
            background: hsla(210deg, 100%, 56%, 0.05);

            &:hover {
                background: hsl(210deg, 80%, 95%);
            }
        }
    }

    .errorPartition td {
        background: #ff8d002e !important;
        color: #0005;
    }
}

.nestedTable .ant-table-container tr {

    &:nth-child(2n+1) {
        background: hsl(0deg, 0%, 98%) !important;
    }

    &>th {
        padding: 8px 2px !important;
    }

    &>td {
        padding: 3px 2px !important;
    }
}

// Hide scrollbar when it's not needed
.ant-table-body {
    overflow-y: auto !important;
}


.brokerTagList {
    display: inline-flex;
    gap: 1px;
    border-radius: 4px;
    background: hsl(0deg, 0%, 85%);

    .broker-tag {
        display: inline-flex;

        position: relative;
        border: none;
        // font-size: 13px;
        font-weight: 500;
        user-select: none;
        // padding: 1px 9px;
        margin: 0;
        padding: 1px 12px;
        border-radius: 0;
        background: hsl(207deg, 10%, 95%);
        color: hsl(0deg, 0%, 45%);

        &.leader {
            &::after {
                @include beforeAfter;

                border-radius: 3px;

                border: 0px solid hsl(0deg, 0%, 60%);
                border-bottom-width: 2px;
            }
        }

        &:hover {
            opacity: 1;
        }

        &.offline {
            background: hsl(0deg, 80%, 76%);
            color: hsl(0deg, 80%, 25%);
        }

        &.added {
            // background: hsl(111deg, 82%, 69%);
            color: hsl(111deg, 100%, 34%);

            &::after {
                @include beforeAfter;

                border-radius: 3px;

                border: 0px solid hsl(105deg, 100%, 40%);
                border-bottom-width: 2px;
            }

            // &::before {
            //     content: '+';
            //     position: absolute;
            //     top: -3px;
            //     left: 1px;

            //     width: 14px;
            //     height: 14px;
            //     background: hsl(9deg, 0%, 66%);
            //     border-radius: 100%;

            //     display: flex;
            //     place-content: center;
            //     place-items: center;

            //     color: white;
            //     font-family: 'Open Sans';
            //     font-size: 15px;
            //     font-weight: bold;

            //     padding-bottom: 2px;
            // }

        }

        &.removed {
            color: hsl(15deg, 75%, 60%);
            // box-shadow: inset #ff7054 0px 0px 2px 1px;

            &::after {
                @include beforeAfter;

                border-radius: 3px;

                border: 0px solid hsl(16deg, 100%, 50%);
                border-bottom-width: 2px;
            }
        }

        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        // The number inside
        span {
            padding: 0 2px;
        }
    }
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
    width: 1px;
    min-width: 0;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    padding: 0 0 0 14px;
    text-align: unset;
}

mark {
    padding: 1px;
    background-color: hsl(54deg, 100%, 50%);
}

.ant-input-suffix {
    span.ant-input-clear-icon {
        position: relative;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            top: -66%;
            right: -66%;
            bottom: -66%;
            left: -66%;

            // background: #f004;
        }
    }
}


.partitionReassignmentSpinner {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;

    vertical-align: text-bottom;
    margin-bottom: 1px;

    border: 2px solid hsl(0, 0%, 85%);
    border-bottom-color: hsl(205, 100%, 50%);
    border-top-color: hsl(205, 100%, 50%);
    animation: rotate 1.5s linear 0s infinite;

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.ant-table-filter-trigger {
    width: 32px;
    background: linear-gradient(to right, transparent, hsl(0deg, 0%, 94%));
    // mix-blend-mode: multiply;

    transition: background-color ease-out 0.1s;
    color: hsl(0deg, 0%, 67%);

    &.active {
        background: hsl(208deg, 100%, 93%);
        //    mix-blend-mode: luminosity;
    }

    &:hover {
        background: hsl(0deg, 0%, 89%);
        //     mix-blend-mode: luminosity;


        .tableFilterIcon {
            color: hsl(0deg, 0%, 60%);
        }
    }

    .tableFilterIcon {
        @include tlrb;
        position: absolute;
        color: hsl(0deg, 0%, 67%);
        font-size: 14px;

        &.active {
            background: hsl(208deg, 100%, 93%);
            color: #1890ff;
        }
    }
}

.ant-table-filter-dropdown {
    box-shadow:
        0px 1px 3px 1px rgba(0, 0, 0, 0.05),
        0px 2px 4px 2px rgba(0, 0, 0, 0.06),
        0px 1px 5px 2px rgba(0, 0, 0, 0.10);
    border-radius: 3px;
}


.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
    padding-right: 3em;
}

.tableInlineSearchBox {
    .ant-input-affix-wrapper {
        // transform-origin: right 50%;
        transform-origin: center center;
        // ease-out ^3
        animation: visible 120ms cubic-bezier(0.19, 1, 0.22, 1) 0ms 1;

        $zoom: 0.96;
        $aspectRatio: 5;
        $initialScale: $zoom, (($zoom - 1) * $aspectRatio) + 1;


        @keyframes visible {
            from {
                opacity: 0;
                // transform: scale(0.95, 0.8) perspective(900px) rotateX(45deg) translateY(8px);
                transform: scale($initialScale);
            }

            to {
                opacity: 1;
                // transform: scale(1.0) perspective(900px) rotateX(0deg) translateY(0px);
                transform: scale(1);
            }
        }

    }
}

.devOnly {
    padding: 1em;
    background: hsl(53deg, 100%, 80%);
}


// table
.activeReassignments {

    // slow animation of progress bar
    .ant-progress-status-active .ant-progress-bg::before {
        animation: ant-progress-active 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    }

    // make rows appear clickable
    tr {
        cursor: pointer;

        &:hover {
            color: hsl(205, 100%, 50%);
            background: hsl(205, 100%, 98%);
        }
    }
}





// Container for text that has the tooltip
.tooltip {
    position: relative;
    display: inline-block; // don't expand to fill the container

    // Text shown in the bubble
    .tooltiptext {
        position: absolute;
        z-index: 1;
        visibility: hidden;
        min-width: 60px;

        padding: .5em .75em;
        border-radius: 4px;

        background-color: hsla(210, 14%, 20%, 0.9);
        color: hsl(0, 0%, 87%);
        text-align: center;

        font-size: 11px;
        font-family: Verdana, 'Segoe UI', Tahoma, Geneva, sans-serif;
        line-height: 1.5;

        // position at top
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);

        // animate
        opacity: 0;
        transition: opacity 150ms 300ms;


        // make tooltip not instantly dissapear when trying to copy from it
        &::after {
            $space: -6px;

            content: ' ';
            position: absolute;
            top: $space;
            left: $space;
            bottom: $space;
            right: $space;
            z-index: -1;
            background: transparent;
        }

    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

    code {
        background: hsl(210deg, 8%, 35%);
        border-radius: 2px;
        padding: 2px 4px;
        line-height: 2;
    }
}

.nbspSeparator {
    white-space: nowrap;
    font-size: 66%;
}

.ant-modal-content {
    border-radius: 4px;

    .ant-modal-header {
        border-radius: 4px;
    }

    .ant-modal-footer {
        button {
            min-width: 8em;
        }
    }
}


.groupDetails {
    .iconButton {
        position: relative;
        display: inline-block;
        color: #9EA6B2;
        width: 20px;
        height: 20px;

        &::after {
            @include beforeAfter;
            border-radius: 2px;
            background-color: transparent;
            margin: -4px;
        }

        // unless disabled...
        &:not(.disabled) {

            // darker color on hover
            &:hover {
                color: #6d727a;

                &::after {
                    background-color: #0001;
                }
            }

            // slight background when pressed
            &:active {
                &::after {
                    background-color: #0002;
                }
            }
        }

        cursor: pointer;
    }
}

// disabled iconButtons use 0.6 opacity
.iconButton.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

// iconButton in a table row is invisible unless hovered
tr:not(:hover) .iconButton {
    opacity: 0;
}


$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);

$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);

$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);

.crossFade {
    $fadeTimeIn: 400ms;
    $fadeTimeOut: 400ms;
    $fadeTimeHeight: 200ms;

    outline: solid red 2px;

    &-enter {
        position: absolute;
        opacity: 0;
        transform: translateX(-20px) scale(1);
    }


    &-enter-active {
        opacity: 1;

        transition:
            opacity $fadeTimeIn $easeOutCirc,
            transform $fadeTimeIn $easeOutCirc;

        transform: translateX(0) scale(1);
    }

    &-leave {
        position: absolute;
        opacity: 1;
        transform: translateX(0em) scale(1);
    }

    &-leave-active {
        opacity: 0;
        transform: translateX(10px) scale(1);

        transition:
            opacity $fadeTimeOut $easeOutCirc,
            transform $fadeTimeOut $easeOutCirc;
    }


    &-height {
        transition:
            height $fadeTimeHeight $easeOutCirc,
            width $fadeTimeHeight $easeOutCirc;
    }
}


// Reduce paddings for small table
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small tfoot>tr>th,
.ant-table.ant-table-small tfoot>tr>td {
    padding: 6px 8px;
}

.consumerGroupModal {
    &-edit {
        // edit dialog
    }

    &-delete {
        // delete dialog
    }

    .ant-collapse-content-box {
        padding: 8px;
    }

    .ant-table.ant-table-small {

        .ant-table-tbody,
        .ant-table-thead {
            &>tr {

                &.unchanged {
                    color: rgba(0, 0, 0, 0.3);
                }

                &>th.ant-table-cell {
                    padding-left: 10px;
                }

                &>td {
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &:first-child {
                        padding-left: 16px;
                    }
                }

            }
        }


    }

    .ant-table-wrapper {
        .ant-pagination {
            // curent antd has a bug: pagination position 'none' has no effect, so we fix it in css
            display: none;
        }
    }


}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small tfoot>tr>th,
.ant-table.ant-table-small tfoot>tr>td {
    // color: red;
}


// when a some container containing a radio button is animated,
// it sometimes causes the inner part (the "dot") of a radio button to become misaligned
// adding a perspective transform fixes it (wtf chrome? just do it correctly like firefox...)
.ant-radio-inner {
    transform: perspective(1px);
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 8px;
    padding: 0;
    padding-top: 3em;
    padding-bottom: 1em;
    margin-top: auto;

    color: hsl(216deg, 11%, 66%);

    .links {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1em;
        font-size: 20px;

        a {
            width: 32px;
            height: 32px;
        }

        .anticon {
            padding: 6px;
            cursor: pointer;

            color: hsl(216deg, 11%, 66%);

            &:hover {
                color: hsl(216deg, 11%, 50%);
            }
        }
    }

    .versionText {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2em;

        white-space: nowrap;

        .versionGitData {
            font-family: monospace;
        }

        font-family: "Poppins",
        sans-serif;
        font-size: 12px;
        // font-weight: 600;
        line-height: normal;
    }
}

.profile {
    display: flex;
    align-items: center;
    height: 62px;
    flex-shrink: 0;

    padding: 0 18px;
    gap: 11px;

    background: hsl(216deg, 21%, 24%);
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    user-select: none;

    transition: all 250ms;

    $avatarSize: 34px;

    .avatar {

        border-radius: 200px;
        overflow: hidden;

        min-width: $avatarSize;
        width: $avatarSize;
        max-width: $avatarSize;

        image-rendering: crisp-edges;
        image-rendering: -webkit-optimize-contrast;

        transition: all 200ms;

        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: scale-down;
        }

    }


    .text {
        font-weight: 300;
        white-space: nowrap;
        max-width: 100%;

        overflow: hidden;

        transition:
            max-width 200ms,
            opacity 300ms;

        .userName {
            max-width: 100%;
            font-size: 12.5px;
            color: #fff;
            padding-bottom: 1px;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        .prefText {
            max-width: 100%;
            font-size: 11.5px;
            color: #9FA6B2;
        }
    }

    &:hover {
        background: hsl(216deg, 21%, 27%);
    }

    @at-root .ant-layout-sider-collapsed & {

        background-color: transparent;

        .avatar {
            margin-left: 6px;
        }

        .text {
            // display: none;
            max-width: 0px;
            opacity: 0;
        }
    }

}

.avatarDropdown {
    animation-duration: 100ms;
}

.userMenu {
    display: flex;
    flex-direction: column;
    min-width: 180px;

    padding-bottom: 3px;
    padding-top: 3px;

    background-color: #fff;
    border-radius: 2px;

    font-family: 'Poppins', sans-serif;
    font-weight: 400;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26);

    .divider {
        height: 1px;
        width: 100%;
        margin: 3px 0px;
        background-color: hsl(215deg, 21%, 93%);

        pointer-events: none;
        cursor: default;
    }

    .menuItem {
        padding: 6px 14px;

        font-size: 13px;
        font-weight: 400;
        color: hsl(0, 0%, 25%);

        cursor: pointer;
        transition: background-color 100ms ease;

        &:hover {
            background-color: hsl(215deg, 21%, 90%);
        }
    }

    .header {
        pointer-events: none;
        padding: 8px 14px;
    }
}

// The inline view thing in the Messages table (value column), that contains all the 'preview results'
.previewTags {
    display: inline-flex;
    column-gap: 12px;
    row-gap: 2px;

    .previewTag {
        display: inline-flex;
        place-items: center;
        gap: 4px;

        .path {
            font-family: monospace;
            font-size: x-small;
            padding: 3px 6px;
            margin-top: 2px;

            border-radius: 10px;
            background: hsl(0deg, 0%, 90%);
            color: hsl(0deg, 0%, 30%);
        }

    }

    &-single {
        // one row for all results, overflow is just hidden
    }

    &-wrap {
        // simple wrapping
        flex-wrap: wrap;
    }

    &-rows {
        // new row for each result
        flex-direction: column;
    }
}

.previewTagsList {
    padding: .5em;
    border-radius: 4px;
    background: rgba(200, 205, 210, 0.16);

    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;

    .moveHandle {
        display: inline-flex;
        align-self: stretch;
        place-items: center;
        width: 28px;
        margin-left: 2px;
        padding-top: 1px;

        color: hsl(0deg, 0%, 58%);
    }

    .description {
        padding-left: 8px;
        font-size: 12px;
        color: hsl(0deg, 0%, 40%);
    }

    input.ant-select-selection-search-input {
        height: 21px;
    }

    .inlineButton {
        display: inline-flex;
        align-self: stretch;
        place-items: center;
        width: 26px;

        color: hsl(0deg, 0%, 58%);
        cursor: pointer;
    }
}

.previewTagsSettings {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 8px;

    .ant-radio-group {
        .ant-radio-button-wrapper {
            padding: 0 12px;
            height: 26px;

            line-height: 24px;
        }
    }
}

.globHelpGrid {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: auto auto auto;
    gap: 2px 18px;

    align-items: center;
    margin-top: 1em;
    margin-bottom: .2em;

    // Title Bold
    .h {
        font-weight: 500;
    }

    // Pattern
    .c1 {}

    // Example results fade out
    .c2 {
        position: relative;
        max-width: 220px;

        font-family: monospace;
        font-size: small;

        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;

        $maskImg: linear-gradient(to left,
                transparent,
                white 40px,
                white);
        mask-image: $maskImg;
        -webkit-mask-image: $maskImg;

        mask-repeat: no-repeat;
        mask-position: right;
    }

    .c3 {
        max-width: 300px;

        font-size: 12px;
    }

    // First and last columns get padding
    .h:first-of-type,
    .c1 {
        padding-left: 8px;
    }

    .rowSeparator {
        height: 1px;
        background: hsl(0deg, 0%, 92%);
        grid-column-end: span 3;
    }

    .titleRowSeparator {
        @extend .rowSeparator;
        margin-top: 2px;
    }
}

.linkBtn {
    cursor: pointer;
    color: hsl(205, 100%, 50%);

    &:hover {
        text-decoration: solid underline;
    }

    &.disabled {
        opacity: 0.5;
        color: black;
        pointer-events: none;
    }
}

.inlineSeparator {
    display: inline-block;

    height: 16px;
    width: 1px;
    margin-top: 2px;
    margin-bottom: 2px;

    background: hsl(0deg, 0%, 80%);
}

.connectorsTable,
.tasksTable {
    tr:hover {
        .hoverLink {
            // text-decoration: solid underline;
            text-decoration-color: #444;

            &:hover {
                text-decoration-color: unset;
            }
        }
    }
}

.connectorLogo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    transform: translateZ(0); // fixes images becoming blurry when downscaled (for whatever reason)
}

.filterCheckbox {
    &::after {
        @include beforeAfter();
        width: 100%;
        height: 100%;

    }
}

.capitalize {
    text-transform: capitalize;
}

.dynamicInputs {
    display: grid;
    row-gap: 1.5em;
    column-gap: 1.2em;

    grid-template-columns: 1fr 1fr 1fr;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    // max-width: 950px;
    align-items: end;


    .none {
        opacity: 0.5;
    }

    .short {
        grid-column: span 1;
    }

    .medium {
        grid-column: span 2;
    }

    .long {
        grid-column: span 3;
    }


    .ant-input-number,
    .ant-select {
        width: 100%;
    }
}

.inputWrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    &::after {
        @include beforeAfter();
        pointer-events: none;

        border: 1px solid hsl(0deg, 100%, 65%);
        box-shadow: 0px 0px 3px 1px #ffaeae;
        border-radius: 2px;

        opacity: 0;
        $scaleY: 1.5;
        $scaleFactor: 20;
        $scaleX: math.div($scaleY - 1, $scaleFactor) + 1;

        transform: scale($scaleX, $scaleY);

        transition: opacity 150ms ease-out,
            transform 250ms ease-out;
    }

    .validationFeedback {
        position: absolute;
        z-index: 1;
        min-width: 10px;

        // position at top
        top: 100%;
        left: 0px;
        transform: translateY(-30%);
        padding: 3px 4px 2px 4px;

        // style
        background: hsla(0deg, 100%, 87%, 0.80);
        color: hsl(0deg, 100%, 50%);

        border-radius: 0px 0px 2px 2px;

        font-size: 11px;
        font-family: Verdana, 'Segoe UI', Tahoma, Geneva, sans-serif;
        line-height: 1.5;
        word-break: break-all;

        // animate
        opacity: 0;
        transition: opacity 150ms ease-out,
            transform 250ms ease-out;

        user-select: none;


        // Single line, unless clicked
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &:active {
            white-space: normal;
            overflow: visible;
        }

        .errorCount {
            display: inline-block;
            padding: 0px 4px;
            padding-bottom: 1px;
            margin-inline-end: 4px;

            transform: translateY(-1px);

            background: hsl(5deg, 100%, 50%);
            color: white;
            border-radius: 16px;
            font-size: 10px;
        }
    }

    &.hasError {
        background: red;

        &::after {
            transform: scale(1);
            opacity: 1;
        }

        .validationFeedback {
            transform: translateY(0);
            opacity: 1;
        }
    }

}

.dynamicInputs>div {

    .importanceTag {

        // Can't find a style that looks good and doesn't overload the UI at the moment.
        display: none;

        margin-left: auto;

        margin-top: 2px;
        padding: 1px 6px;
        border-radius: 4px;

        font-size: 0.7em;
        font-weight: 500;

        user-select: none;
        text-transform: capitalize;

        // default hidden
        // opacity: 0.66;
        opacity: 1;
        transition:
            opacity 300ms ease-out;

        &.high {
            // background: #f20;
            // color: #fff;
            // background: #fff;
            color: rgba(0, 123, 255, 0.726);
            background: rgba(0, 123, 255, 0.1);

        }

        &.medium {
            // background: #f20;
            // color: #fff;
        }

        &.low {
            background: rgba(119, 119, 119, 0.035);
            color: #777;

            // font-style: italic;
        }
    }

    &:hover,
    &:focus-within {
        .importanceTag {
            opacity: 1;
        }
    }

}


.stringList {
    display: flex;
    flex-direction: column;
    // gap: .5em;

    .createEntryRow {
        display: flex;
        gap: .5em;
        align-items: center;
        height: 29px;

        margin: 6px 0px;

        &>input,
        &>button {
            height: 100%;
        }
    }

    .reorderableList {

        .draggableItem {
            display: flex;
            align-items: center;
            gap: 4px;

            background: hsl(0deg, 0%, 97%);

            height: 32px;
            // padding: 0px 4px;
            border-radius: 3px;
            overflow: hidden;

            margin-bottom: 4px;

            .dragHandle {
                display: inline-flex;
                align-self: stretch;

                place-items: center;
                width: 28px;
                margin-left: 2px;
                padding-top: 1px;
                color: hsl(0deg, 0%, 70%);

                transform: scale(0.8);
            }

            input {
                height: 28px;
                // margin-right: 4px;
            }


            // Normal
            .ghostInput:not(:focus-within) {

                border-color: transparent;
                background-color: transparent;

                transition:
                    border-color 350ms ease-out,
                    background-color 100ms ease-out;
            }

            // Hover
            .ghostInput:hover:not(:focus-within) {
                cursor: pointer;
                border-radius: 2px;
            }

            // Active
            .ghostInput {

                transition:
                    border-color 80ms ease-out,
                    background-color 80ms ease-out;
            }


            .deleteButton {
                display: inline-flex;
                align-self: stretch;
                place-items: center;
                width: 32px;
                // margin-right: 4px;
                padding-right: 2px;

                color: hsl(0deg, 0%, 70%);
                cursor: pointer;

                opacity: 0; // only visible on hover
                transition: opacity 300ms;

                &>svg {
                    transform: scale(1.1);
                }

                &:hover {
                    color: hsl(0, 0%, 45%);
                    background: hsl(0, 0%, 95%);
                }
            }

            &:hover>.deleteButton {
                opacity: 1;
                transition: opacity 120ms;
            }
        }



    }
}

.ant-btn[disabled] {
    cursor: default !important;
}

.inputHintTooltip>.ant-tooltip-content {
    .ant-tooltip-arrow {
        display: none;
    }

    .ant-tooltip-inner {
        padding: 4px 8px;
        background-color: hsla(210deg, 14%, 25%, 90%);
        box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    }
}

.noArrow .ant-popover-arrow {
    display:none;
}