//.wizard {
//  :global(.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon) > :global(.ant-steps-icon),
//  :global(.ant-steps-item-finish .ant-steps-item-icon) > :global(.ant-steps-icon) {
//    color: #5850EC;
//  }
//
//  :global(.ant-steps-item-finish) > :global(.ant-steps-item-container) > :global(.ant-steps-item-content) > :global(.ant-steps-item-title::after),
//    background-color: #5850EC;
//  }
//}

.steps {
    margin-bottom: 35px;
}

.step {
    cursor: default;
}

.content {
    margin-bottom: 35px;
}

.footer {
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.nextButton {
    margin-left: auto;
}

.prevButton {
    margin-right: 15px;
}

.nextButton,
.prevButton {
    display: inline-flex;
    align-items: center;
}