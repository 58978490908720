.filterbar {
    display: flex;
    flex-wrap: wrap;
}

.filters {
    width: calc(100% - 200px);
    display: inline-flex;
    row-gap: 2px;
    flex-wrap: wrap;
}

.filterName {
    display: inline-block;
}

.addFilter {
    vertical-align: middle;
    margin-top: -3px;
}

.metaSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    width: 200px;

    font-size: 12px;
    font-weight: 500;
    padding: 0 5px;

    .bytesIcon {
        color: #1890ff;
    }

    .time {
        margin-left: 20px;
    }

    .timeIcon {
        color: #1890ff;
    }
}

.deleteButtonWrapper,
.quickSearch {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteButtonWrapper {
    margin-left: auto;
}

.quickSearchWrapper {
    margin-left: 1rem;
}

.quickSearchInput {
    width: 200px;
    padding: 2px 8px;
    white-space: nowrap;
    height: 32px;
}
